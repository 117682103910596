
body {
  margin: 0;
  font-family: 'Titillium Web', sans-serif, sans-serif;
    -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: rgb(240,255,240);
background: radial-gradient(circle, rgba(240,255,240,1) 0%, rgba(233,148,220,0.8534007352941176) 0%, rgba(160,233,148,0.8534007352941176) 41%, rgba(232,233,148,1) 60%, rgba(232,233,148,0.8534007352941176) 87%);
  font-weight: 800 !important; */
  background-color: #ffe14b;
  font-weight: 600;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a{
  text-decoration: none;
}

